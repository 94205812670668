export default [
  // {
  //   dependancies: [],
  //   name: 'policies',
  //   title: 'policies',
  // },
  {
    dependancies: [],
    name: 'Home',
    roles: [
      {
        arabicName: 'عرض',
        englishName: 'view Home',
        id: 'viewHome',
      }
    ],
    title: 'Home',
  },
  {
    dependancies: [],
    name: 'Statistics',
    roles: [
      {
        arabicName: 'إحصائيات الموظفين',
        englishName: 'employee statistics',
        id: 'viewEmployeesDashboard',
      },
    ],
    title: 'Statistics',
  },
  {
    dependancies: [],
    name: 'income',
    title: 'income',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewIncomes'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addIncomes'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editIncomes'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteIncomes'
      }
    ]
  },
  {
    name: 'expense',
    title: 'expense',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewExpenses',
        type: 'mobile'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addExpenses'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editExpenses'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteExpenses'
      }
    ]
  },
  {
    dependancies: [],
    name: 'discount',
    title: 'discount',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewDiscounts',
        type: 'mobile'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addDiscounts'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editDiscounts'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteDiscounts'
      }
    ]
  },
  {
    dependancies: [],
    name: 'banks',
    title: 'banks',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewBanks',
        type: 'mobile'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addBanks'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editBanks'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteBanks'
      }
    ]
  },
  {
    dependancies: [],
    name: 'paymentMethods',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewPaymentMethods' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addPaymentMethods' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editPaymentMethods' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deletePaymentMethods' },
    ],
    title: 'PaymentMethods',
  },
  {
    dependancies: [],
    name: 'safes',
    type: 'mobile',
    roles: [{
      arabicName: 'عرض',
      englishName: ' View',
      id: 'viewSafes',
      type: 'mobile'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addSafes'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editSafes'
      },
      {
        arabicName: 'حذف',
        englishName: 'Delete',
        id: 'deleteSafes'
      },
      // {
      //   arabicName: 'فتح',
      //   englishName: ' Open',
      //   id: 'openSafes',
      //   type: 'mobile'
      // },
      // {
      //   arabicName: 'قفل',
      //   englishName: ' Close',
      //   id: 'closeSafes',
      //   type: 'mobile'
      // },
    ],
    title: 'safes',
  },
  {
    dependancies: [],
    name: 'suppliers',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewSuppliers' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addSuppliers' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editSuppliers' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteSuppliers' },
    ],
    title: 'suppliers',
  },
  {
    dependancies: [],
    name: 'areas',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewAreas' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addAreas' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editAreas' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteAreas' },
    ],
    title: 'areas',
  },
  {
    dependancies: [],
    name: 'drivers',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewDrivers' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addDrivers' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editDrivers' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteDrivers' },
    ],
    title: 'drivers',
  },
  {
    dependancies: [],
    name: 'buses',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewBuses' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addBuses' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editBuses' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteBuses' },
    ],
    title: 'buses',
  },
  {
    name: 'nationalities',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewNationalities' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addNationalities' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editNationalities' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteNationalities' },
    ],
    title: 'nationalities',
  },
  {
    name: 'studentStatuses',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewStudentStatuses' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addStudentStatuses' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editStudentStatuses' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteStudentStatuses' },
    ],
    title: 'studentStatuses',
  },
  {
    name: 'serviceCategories',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewServiceCategories' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addServiceCategories' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editServiceCategories' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteServiceCategories' },
    ],
    title: 'serviceCategories',
  },
  {
    dependancies: [],
    name: 'services',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewServices' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addServices' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editServices' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteServices' },
    ],
    title: 'services',
  },
  {
    name: 'stages',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewStages' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addStages' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editStages' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteStages' },
    ],
    title: 'stages',
  },
  {
    name: 'rows',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewRows' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addRows' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editRows' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteRows' },
    ],
    title: 'rows',
  },
  {
    name: 'classes',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewClasses' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addClasses' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editClasses' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteClasses' },
    ],
    title: 'classes',
  },
  {
    name: 'guardians',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewGuardians' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addGuardians' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editGuardians' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteGuardians' },
    ],
    title: 'guardians',
  },
  {
    name: 'students',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewStudents' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addStudents' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editStudents' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteStudents' },
      { arabicName: 'إيقاف الطالب', englishName: 'Suspend Students', id: 'suspendStudents' }
    ],
    title: 'students',
  },
  {
    name: 'employees',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewEmployees' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addEmployees' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editEmployees' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteEmployees' },
    ],
    title: 'employees',
  },
  {
    name: 'maritalStatus',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewMaritalStatus' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addMaritalStatus' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editMaritalStatus' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteMaritalStatus' },
    ],
    title: 'maritalStatus',
  },
  {
    name: 'qualification',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewQualification' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addQualification' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editQualification' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteQualification' },
    ],
    title: 'qualification',
  },
  {
    name: 'sections',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewSections' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addSections' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editSections' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteSections' },
    ],
    title: 'sections',
  },
  {
    name: 'jopTitle',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewJopTitle' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addJopTitle' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editJopTitle' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteJopTitle' },
    ],
    title: 'jopTitle',
  },
  {
    name: 'mangement',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewMangement' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addMangement' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editMangement' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteMangement' },
    ],
    title: 'mangement',
  },
  {
    name: 'religion',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewReligion' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addReligion' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editReligion' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteReligion' },
    ],
    title: 'religion',
  },
  {
    name: 'typeVacations',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewTypesVacation' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addTypesVacation' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editTypesVacation' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteTypesVacation' },
    ],
    title: 'typeVacations',
  },
  {
    dependancies: [],
    name: 'vacationRequest',
    title: 'vacationRequest',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewVacationRequest'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addVacationRequest'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editVacationRequest'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteVacationRequest'
      }
    ]
  },
  {
    dependancies: [],
    name: 'benefitsAndDeduction',
    title: 'benefitsAndDeductions',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewBenefitAndDeduction'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addBenefitAndDeduction'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editBenefitAndDeduction'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteBenefitAndDeduction'
      }
    ]
  },
  {
    dependancies: [],
    name: 'advancedPayments',
    title: 'advancedPayments',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewAdvancePayment'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addAdvancePayment'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editAdvancePayment'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteAdvancePayment'
      }
    ]
  },
  {
    dependancies: [],
    name: 'calculateSalary',
    title: 'calculateSalary',
    roles: [{
      arabicName: 'عرض',
        englishName: ' View',
        id: 'viewCalculateSalary'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addCalculateSalary'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
         id: 'editCalculateSalary'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteCalculateSalary'
      }
    ]
  },
  // {
  //   name: 'employees',
  //   title: 'employees',
  // },
  {
    dependancies: [],
    name: 'salesTransactions',
    type: 'mobile',
    roles: [{
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewSalesTransactions',
        type: 'mobile'
      },
    ],
    title: 'allInovice',
  },
  {
    name: 'salesTransactions',
    roles: [
      { id: 'addSalesTransactions', arabicName: 'إضافة', englishName: ' Add' },
      { id: 'editSalesTransactions', arabicName: 'تعديل', englishName: ' Edit' },
      { id: 'deleteSalesTransactions', arabicName: 'حذف', englishName: ' Delete' },
      { id: 'sendSalesTransactionsEmail', arabicName: 'إرسال بريد إلكتروني', englishName: ' Send Email' },
    ],
    dependancies: [],
    title: 'salesInvoices',
  },

  {
    dependancies: [],
    name: 'returnSalesTransactions',
    type: 'mobile',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewReturnSalesTransactions',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addReturnSalesTransactions',
        type: 'mobile'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editReturnSalesTransactions',
        type: 'mobile'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteReturnSalesTransactions',
        type: 'mobile'
      },
    ],
    title: 'returnSales',
  },
  {
    name: 'deservedFees',
    roles: [
      { id: 'viewDeservedFees', arabicName: 'عرض', englishName: 'View' },
      { id: 'addDeservedFees', arabicName: 'إضافة', englishName: 'Add' },
      { id: 'editDeservedFees', arabicName: 'تعديل', englishName: 'Edit' },
      { id: 'deleteDeservedFees', arabicName: 'حذف', englishName: 'Delete' },
      { id: 'printDeservedFees', arabicName: 'طباعة', englishName: 'Print' },
      { id: 'convertToSalesInvoice', arabicName: 'تحويل إلي فاتورة مبيعات', englishName: 'Convert To Invoice Sales' },
    ],
    dependancies: [],
    title: 'deservedFees',
  },
  {
    name: 'temporaryStudents',
    roles: [
      { id: 'viewRegistrationRequests', arabicName: 'عرض', englishName: 'View' },
      { id: 'acceptRegistrationRequest', arabicName: 'قبول الطلاب المؤقتين', englishName: 'Accept Temporary Students' },
    ],
    dependancies: [],
    title: 'temporaryStudents',
  },

  // ------------ start items ------------
  {
    dependancies: [],
    name: 'units',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewUnits' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addUnits' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editUnits' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteUnits' },
    ],
    title: 'units',
  },
  {
    dependancies: [],
    name: 'itemCategories',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewItemCategories' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addItemCategories' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editItemCategories' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteItemCategories' },
    ],
    title: 'itemsCategories',
  },
  {
    dependancies: [],
    name: 'items',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewItems' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addItems' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editItems' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteItems' },
    ],
    title: 'itemsList',
  },

  // ------------- start purchases --------------
  {
    dependancies: [],
    name: 'stores',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewStores' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addStores' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editStores' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteStores' },
    ],
    title: 'warehouses',
  },
  {
    dependancies: [],
    name: 'purchTransactions',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewPurchTransactions' },
      { arabicName: 'عرض الكل', englishName: ' View all', id: 'viewAllPurchTransactions' }
    ],
    title: 'previewPurchases',
  },
  // {
  //   dependancies: [],
  //   name: 'purchOrderTransactions',
  //   roles: [
  //     { arabicName: 'عرض', englishName: ' View', id: 'viewPurchOrders' },
  //     { arabicName: 'إضافة', englishName: ' Add', id: 'addPurchOrders' },
  //     { arabicName: 'تعديل', englishName: ' Edit', id: 'editPurchOrders' },
  //     { arabicName: 'حذف', englishName: ' Delete', id: 'deletePurchOrders' },
  //   ],
  //   title: 'purchOrder',
  // },
  {
    dependancies: [],
    name: 'purchTransactions',
    roles: [
      { arabicName: 'إضافة', englishName: ' Add', id: 'addPurchTransactions' },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editPurchTransactions',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deletePurchTransactions',
      },
    ],
    title: 'purch',
  },
  // {
  //   dependancies: [],
  //   name: 'returnPurchTransactions',
  //   roles: [
  //     {
  //       arabicName: 'عرض',
  //       englishName: ' View',
  //       id: 'viewReturnPurchTransactions',
  //     },
  //     {
  //       arabicName: 'إضافة',
  //       englishName: ' Add',
  //       id: 'addReturnPurchTransactions',
  //     },
  //     {
  //       arabicName: 'تعديل',
  //       englishName: ' Edit',
  //       id: 'editReturnPurchTransactions',
  //     },
  //     {
  //       arabicName: 'حذف',
  //       englishName: ' Delete',
  //       id: 'deleteReturnPurchTransactions',
  //     },
  //   ],
  //   title: 'returnPurch',
  // },

  // ------------ start stores -------------
  {
    dependancies: [],
    name: 'stockCategoryTransactions',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewStockCategoryTransactions' },
    ],
    title: 'orders',
  },
  {
    dependancies: [],
    name: 'exchangeOrders',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewExchangeOrders' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addExchangeOrders' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editExchangeOrders' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteExchangeOrders' },
    ],
    title: 'exchangeOrder',
  },
  {
    dependancies: [],
    name: 'additionOrders',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewAdditionOrders' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addAdditionOrders' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editAdditionOrders' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteAdditionOrders' },
    ],
    title: 'additionOrder',
  },
  {
    dependancies: [],
    name: 'storageTransfer',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewStorageTransfer' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addStorageTransfer' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editStorageTransfer' },
    ],
    title: 'storageTransfer',
  },
  {
    dependancies: [],
    name: 'damageAdjustment',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewDamageAdjustments',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addDamageAdjustments',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editDamageAdjustments',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteDamageAdjustments',
      },
    ],
    title: 'damageAdjustment',
  },

  // ------------ start stores warehouse ------------
  {
    dependancies: [],
    name: 'stocktaking',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewStocktaking',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addStocktaking',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editStocktaking',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteStocktaking',
      },
      {
        arabicName: 'اغلاق',
        englishName: 'Close',
        id: 'closeStocktaking',
      },
    ],
    title: 'inventory',
  },
  {
    dependancies: [],
    name: 'shortageAdjustment',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewShortageAdjustments',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addShortageAdjustments',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editShortageAdjustments',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteShortageAdjustments',
      },
    ],
    title: 'shortageAdjustment',
  },
  {
    dependancies: [],
    name: 'overflowAdjustment',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewOverflowAdjustments',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addOverflowAdjustments',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editOverflowAdjustments',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteOverflowAdjustments',
      }
    ],
    title: 'overflowAdjustment',
  },

  // ------------ start transactions ------------
  {
    dependancies: [],
    name: 'collectionTransactions',
    type: 'mobile',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewCollectionTransactions',
        type: 'mobile'
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addCollectionTransactions',
        type: 'mobile'
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editCollectionTransactions',
        type: 'mobile'
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteCollectionTransactions',
        type: 'mobile'
      },
      {
        arabicName: 'إرسال بريد إلكتروني',
        englishName: 'send E-Mail',
        id: 'sendCollectionTransactionsEmail',
        type: 'mobile'
      },
      {
        arabicName: 'إرسال واتس اب',
        englishName: 'send Whatsapp',
        id: 'sendCollectionTransactionsWhatsapp',
        type: 'mobile'
      },
      {
        arabicName: 'تخصيص مبلغ ايصال الاستلام',
        englishName: ' Collection Transaction Allocation,',
        id: 'collectionTransactionAllocation',
      },
    ],
    title: 'Collection Transaction',
  },
  {
    dependancies: [],
    name: 'paymentTransactions',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewPaymentTransactions',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addPaymentTransactions',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editPaymentTransactions',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deletePaymentTransactions',
      },
      {
        arabicName: 'تخصيص مبلغ ايصال الدفع',
        englishName: ' Payment Transaction Allocation,',
        id: 'paymentTransactionAllocation',
      },
    ],
    title: 'paymentTransaction',
  },
  {
    name: 'studentDiscounts',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewStudentDiscounts',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addStudentDiscounts',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editStudentDiscounts',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteStudentDiscounts',
      },
    ],
    title: 'studentDiscounts',
  },
  {
    dependancies: [],
    name: 'expenseTransactions',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewExpenseTransactions',
      },
      {
        arabicName: 'إضافة',
        englishName: ' Add',
        id: 'addExpenseTransactions',
      },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editExpenseTransactions',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteExpenseTransactions',
      },
    ],
    title: 'expenseTransaction',
  },
  {
    dependancies: [],
    name: 'incomeTransactions',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewIncomeTransactions' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addIncomeTransactions' },
      {
        arabicName: 'تعديل',
        englishName: ' Edit',
        id: 'editIncomeTransactions',
      },
      {
        arabicName: 'حذف',
        englishName: ' Delete',
        id: 'deleteIncomeTransactions',
      },
    ],
    title: 'Income Transaction',
  },
  // ------------ end transactions ------------
  // ------------- start accounts -------------
  {
    dependancies: [],
    name: 'accounts',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewAccounts' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addAccounts' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editAccounts' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteAccounts' },
    ],
    title: 'chartOfAccount',
  },
  {
    dependancies: [],
    name: 'costCenters',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewCostCenters' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addCostCenters' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editCostCenters' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteCostCenters' },
    ],
    title: 'costCenter',
  },
  {
    dependancies: [],
    name: 'vouchers',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewVouchers' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addVouchers' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editVouchers' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteVouchers' },
      { arabicName: 'تمكين قيد الاغلاق', englishName: 'Enable Under Closure', id: 'enableUnderClosure' },
      // { arabicName: 'توزيع علي مراكز تكلفة ', englishName: 'Enable Account CostCenters', id: 'distributionToCostCenters' },
      // { arabicName: 'التعديل علي قيد تكامل', englishName: 'Integrated Daily Journal Update', id: 'integratedDailyJournalUpdate' },
      // { arabicName: 'اظهار قيود باقي المستخدمين', englishName: 'Show Other Users Permissions', id: 'showOtherUsersVoucher' },
    ],
    title: 'vouchers',
  },
  {
    dependancies: [],
    name: 'postVouchers',
    roles: [
      { arabicName: 'ترحيل', englishName: 'post', id: 'glPost' },
      { arabicName: 'الغاء الترحيل', englishName: 'unpost', id: 'glUnPost' },
    ],
    title: 'postVouchers',
  },
  {
    dependancies: [],
    name: 'messages',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewMessages' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addMessages' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editMessages' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteMessages' },
      { arabicName: 'إرسال', englishName: ' Delete', id: 'sendMessages' },
    ],
    title: 'messages',
  },
  {
    dependancies: [],
    name: 'discountsCollection',
    roles: [
            { arabicName: 'إضافة', englishName: ' Add', id: 'addDiscountCollection' },
    ],
    title: 'discountsCollection',
  },
  {
    dependancies: [],
    name: 'studentInvoices',
    roles: [
            { arabicName: 'إضافة', englishName: ' Add', id: 'addStudentInvoices' },
    ],
    title: 'studentInvoices',
  },
  {
    dependancies: [],
    name: 'busAccommodation',
    roles: [
            { arabicName: 'إضافة', englishName: ' Add', id: 'addBusAccommodation' },
    ],
    title: 'busAccommodation',
  },
  {
    dependancies: [],
    name: 'school-setting',
    roles: [
      { arabicName: 'عرض', englishName: 'View', id: 'viewSchool' },
      { arabicName: 'تعديل', englishName: 'Edit', id: 'editSchool' },
    ],
    title: 'school-setting',
  },
  {
    dependancies: [],
    name: 'branches',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewBranches' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addBranches' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editBranches' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteBranches' },
    ],
    title: 'Branches',
  },
  {
  dependancies: [],
    name: 'users',
    type: 'mobile',
    roles: [
      {
        arabicName: 'عرض',
        englishName: ' View',
        id: 'viewUsers',
        type: 'mobile'
      },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addUsers' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editUsers' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteUsers' },
    ],
    title: 'users',
  },
  {
    dependancies: [],
    name: 'fiscalYears',
    roles: [
      { arabicName: 'عرض السنوات الماليه', englishName: 'Review', id: 'viewFiscalYears' },
      { arabicName: 'عرض كل السنوات الماليه', englishName: 'viewAllFiscalYears', id: 'viewAllFiscalYears' },
    ],
    title: 'fiscalYears',
  },
  {
    dependancies: [],
      name: 'messages-settings',
      roles: [
        { arabicName: 'إضافة', englishName: ' Add', id: 'addMessageSettings' },
      ],
      title: 'messages-settings',
  },
  // students Reports
  {
    name: 'students-reports',
    roles: [
      {
        id: 'reviewStudentsReports',
        arabicName: 'قائمة الطلاب',
        englishName: 'Student List'
      },
      {
        id: 'reviewStudentsReportsServices',
        arabicName: 'خدمات الطلاب',
        englishName: 'Student Services'
      },
      {
        id: 'reviewStudentsReportsDiscounts',
        arabicName: 'خصومات الطلاب',
        englishName: 'Student Discounts'
      },
      {
        id: 'reviewAccountsStudentreports',
        arabicName: 'كشف حساب الطلاب',
        englishName: 'Student Account Statement'
      },
      {
        id: 'reviewStudentStatusesReport',
        arabicName: 'حالات الطلاب',
        englishName: 'Student Statueses'
      },
      {
        id: 'reviewStudentsSuccessReport',
        arabicName: 'إنجاح الطلاب',
        englishName: 'student Success'
      }
    ],
    dependancies: [],
    title: 'students-reports'
  },
  // employees Reports
  {
    name: 'employeesReport',
    roles: [
      {
        id: 'viewEmployeesData',
        arabicName: 'بيانات الموظف',
        englishName: 'employees Data'
      },
      {
        id: 'viewEmployeesPayslip',
        arabicName: 'كشف الرواتب',
        englishName: 'employees Payslip'
      },
      {
        id: 'viewEmployeesBenefits',
        arabicName: 'الإستحقاقات',
        englishName: 'employees Benefits'
      },
      {
        id: 'viewEmployeesDeductions',
        arabicName: 'الإستقطاعات',
        englishName: 'employees Deduction'
      },
      {
        id: 'viewEmployeesAdvancedPayments',
        arabicName: 'السلف',
        englishName: 'employees Advanced Payment'
      }
    ],
    dependancies: [],
    title: 'employees-reports',
  },
  // accounts Reports
  {
    name: 'accountsReport',
    roles: [
      {
        id: 'reviewAccountStatementDetailsReport',
        arabicName: 'كشف حساب الأستاذ',
        englishName: 'Ledger account statement'
      },
      {
        id: 'reviewTrialBalanceReport',
        arabicName: 'ميزان المراجعة',
        englishName: 'Trial Balance'
      },
      {
        id: 'reviewIncomeStatementReport',
        arabicName: 'قائمة الدخل',
        englishName: 'income list'
      },
      {
        id: 'reviewBankBalanceReport',
        arabicName: 'حساب البنوك',
        englishName: 'banks account'
      },
      {
        id: 'reviewCashBoxAccountStatementReport',
        arabicName: 'حساب الخزن',
        englishName: 'safes account'
      },
      {
        id: 'taxesReport',
        arabicName: 'حساب ضريبة القيمة المضافة',
        englishName: 'Value Additional Taxes Account'
      },
      {
        id: 'reviewSuppliersExpensesReport',
        arabicName: 'مصروفات الموردين',
        englishName: 'Suppliers Expenses'
      },
      {
        id: 'reviewBalanceSheetReport',
        arabicName: 'الميزانية العمومية',
        englishName: 'Balance sheet'
      },
      {
        id: 'reviewCostCenterReport',
        arabicName: 'مراكز التكلفة',
        englishName: 'Cost Center'
      }
    ],
    dependancies: [],
    title: 'accounts-reports',
  },
  // financial Transactions Reports
  {
    name: 'financialtransactionReports',
    roles: [
      {
        id: 'reviewCollectionTransactionsReport',
        arabicName: 'سندات قبض',
        englishName: 'collection Transaction',
        type: 'reports'
      },
      {
        id: 'reviewPaymentTransactionsReport',
        arabicName: 'سندات دفع',
        englishName: 'payment Transaction',
        type: 'reports'
      },
      {
        id: 'reviewReportFinancialTransactionAllocation',
        arabicName: 'تخصيص سندات القبض',
        englishName: 'financial Transaction Allocation',
        type: 'reports'
      },
      {
        id: 'reviewIncomesReport',
        arabicName: 'حركة الإيرادات',
        englishName: 'incomes Transaction',
        type: 'reports'
      },
      {
        id: 'reviewExpensesReport',
        arabicName: 'حركة المصروفات',
        englishName: 'expenses Transaction',
        type: 'reports'
      },
      {
        id: 'reviewReportSalesAllocation',
        arabicName: 'الفواتير المخصصة',
        englishName: 'sales Allocation',
        type: 'reports'
      }
    ],
    dependancies: [],
    title: 'financialtransaction-reports',
  },
  {
    name: 'itemsReport',
    roles: [
      {
        id: 'reviewItemTransactionsTotalsReport',
        arabicName: 'تقرير اجمالي الحركات علي الأصناف',
        englishName: 'Item Transactions Totals Report'
      },
      {
        id: 'reviewItemTransactionsDetailsReport',
        arabicName: 'تقرير حركة الأصناف تفصيل',
        englishName: 'Item Transactions Details Report'
      },
      {
        id: 'reviewStoreBalanceReport',
        arabicName: 'تقرير رصيد المخزن',
        englishName: 'Store Balance Report'
      },
      {
        id: 'reviewStoreEvalutionReport',
        arabicName: 'تقرير تقييم المخزن',
        englishName: 'Store Evalution Report'
      },
      {
        id: 'reviewInventoryReport',
        arabicName: 'تقرير الجرد',
        englishName: 'review Inventory Report'
      },
    ],
    dependencies: [],
    title: 'items-reports'
  },
];
